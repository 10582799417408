import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import { Title, Meta } from "@angular/platform-browser";
import { LanguageService } from "src/app/services/language/language.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	title = "floreceann-portfolio";

	constructor(
		private titleService: Title,
		private metaService: Meta,
		private languageService: LanguageService
	) {}
	ngOnInit(): void {
		this.languageService.initLanguage();

		this.titleService.setTitle(
			"Md Maruf | ASP.NET Developer | Angular Development | Software Engineer"
		);
		this.metaService.addTags([
			{
				name: "keywords",
				content:
					"Backend, ASP.NET Developer , Angular Development , Software Engineer, software, developer",
			},
			{
				name: "description",
				content:
					"As a software engineer with expertise in both FrontEnd and BackEnd, I have a comprehensive understanding of full-stack web development. My strong foundation in C# allows me to effectively work with back-end technologies such as ASP.NET, as well as front-end technologies such as Angular.",
			},
		]);

		AOS.init();
	}
}
